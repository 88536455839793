<template>
  <div class="download">
    <banner img="../assets/img/appDown.jpg" title="下载APP" />
  </div>
</template>

<script>
import Banner from "../components/Banner";
export default {
  components: {
    Banner
  }
};
</script>

<style lang="scss" scoped>
</style>